import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { useInput } from "../../../hook/input-hook";
import { couponValueNumber } from "../../../service/validations/validation";
import { MoonLoader } from "react-spinners";
import { fetch } from "../../../service/utils";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { complaintOptions, syringingOptions, visionCheckBoxList, visionOptions } from "../../../service/constant";
import Input from "../../../admin/components/input/Input.component";

const isEyePresure = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Left Eye Presure." };
  } else {
    return { validated: true, message: "" };
  }
};
const isREyePresure = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Right Eye Presure." };
  } else {
    return { validated: true, message: "" };
  }
};
// ======================left eye message=======================

const isUnAddedNumb = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Left vUn-Added Number." };
  } else {
    return { validated: true, message: "" };
  }
};

const isGlasses = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Left Glasses Number." };
  } else {
    return { validated: true, message: "" };
  }
};

const isSph = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Left vSph Number." };
  } else {
    return { validated: true, message: "" };
  }
};

const isCyl = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Left Cyl Number." };
  } else {
    return { validated: true, message: "" };
  }
};

const isAxis = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Left Enter Axis." };
  } else {
    return { validated: true, message: "" };
  }
};

const isVresult = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Left Enter V- Result." };
  } else {
    return { validated: true, message: "" };
  }
};

const isNearVision = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Left Near Vision." };
  } else {
    return { validated: true, message: "" };
  }
};
// ==============================right msg====================================

const isRUnAddedNumb = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Right Un-Added Number." };
  } else {
    return { validated: true, message: "" };
  }
};

const isRGlasses = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Right Glasses Number." };
  } else {
    return { validated: true, message: "" };
  }
};

const isRSph = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Right Sph Number." };
  } else {
    return { validated: true, message: "" };
  }
};

const isRCyl = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Right Cyl Number." };
  } else {
    return { validated: true, message: "" };
  }
};

const isRAxis = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Right Enter Axis." };
  } else {
    return { validated: true, message: "" };
  }
};

const isRVresult = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Right Enter V- Result." };
  } else {
    return { validated: true, message: "" };
  }
};

const isRNearVision = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Right Enter Near Vision." };
  } else {
    return { validated: true, message: "" };
  }
};

const isRNearVisionResult = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Enter Right Near Vision Result." };
  } else {
    return { validated: true, message: "" };
  }
};
const isRVisionResult = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Enter Right Vision Result." };
  } else {
    return { validated: true, message: "" };
  }
};
// ==============================right msg end here====================================

const isLeftEyeVision = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Left Eye Vision." };
  } else {
    return { validated: true, message: "" };
  }
};
const isRightEyeVision = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Right Eye Vision." };
  } else {
    return { validated: true, message: "" };
  }
};
const isNearVisionResult = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Near Left Eye Vision Result." };
  } else {
    return { validated: true, message: "" };
  }
};




// const isContact = (value) => {
//   if (value === "") {
//     return { validated: false, message: "Please Enter Your Contact Number" };
//   } else if (!numberreg.test(value)) {
//     return { validated: false, message: "Enter 10 digits Contact number" };
//   } else {
//     return { validated: true, message: "" };
//   }
// };


export default function OptometristPatientAssessment({
  addVisionDetail,
  setAddVisionDetail,
  patientId,
  editProperDetail
}) {

  const cancelButtonRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [networkError, setNetworkError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [complaints, setComplaints] = useState([]);

  const [leftEyeSyringing, setLeftEyeSyringing] = useState("");
  const [leftEyeSyringingError, setLeftEyeSyringingError] = useState("");

  const [rightEyeSyringing, setRightEyeSyringing] = useState("");
  const [rightEyeSyringingError, setRightEyeSyringingError] = useState("");

  const [leftEyeVision, setLeftEyeVision] = useState("");
  const [leftEyeVisionError, setLeftEyeVisionError] = useState("");

  const [rightEyeVision, setRightEyeVision] = useState("");
  const [rightEyeVisionError, setRightEyeVisionError] = useState("");

  const [visionType, setVisionType] = useState("");
  const [visionTypeError, setVisionTypeError] = useState("");


  const handleComplaintChange = (complaintId) => {
    setComplaints((prevComplaints) =>
      prevComplaints.includes(complaintId)
        ? prevComplaints.filter((id) => id !== complaintId)
        : [...prevComplaints, complaintId]
    );
  };

  const handleLeftEyeVisionChange = (event) => {
    setLeftEyeVision(event.target.value);
    setLeftEyeVisionError("")

  };
  const handleRighttEyeVisionChange = (event) => {
    setRightEyeVision(event.target.value);
    setRightEyeVisionError("")

  };
  const handleVisionTypeChange = (event) => {
    setVisionType(event.target.value);
    setVisionTypeError("")

  };
  const handleLeftEyeSyringChange = (event) => {
    setLeftEyeSyringing(event.target.value);
    setLeftEyeSyringingError("")

  };

  const handleRightEyeSyringChange = (event) => {
    setRightEyeSyringing(event.target.value);
    setRightEyeSyringingError("")

  };


  const [selectedItemsCheckBox, setSelectedItemsCheckBox] = useState([]);
  const [selectedItemsCheckBoxError, setSelectedItemsCheckBoxError] = useState("");

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedItemsCheckBox((prev) => [...prev, value]); // Add to selected items
      setSelectedItemsCheckBoxError("");

    } else {
      setSelectedItemsCheckBox((prev) => prev.filter((item) => item !== value)); // Remove from selected items
      setSelectedItemsCheckBoxError("");

    }

  };

 // =========================right==============================================
 const [isRUnAddedNumbTouch, setIsRUnAddedNumbTouch] = useState("")
 const [isRGlassesTouch, setisRGlassesTouch] = useState("");
 const [isRSphTouch, setIsRSphTouch] = useState("")
 const [isRCylTouch, setIsRCylTouch] = useState('');
 const [isRAxisTouch, setIsRAxisTouch] = useState("");
 const [isRVresultTouch, setIsRVresultTouch] = useState("")
 const [isRVisionTouch, setIsRVisionTouch] = useState("")
 const [isRNearVisionResultTouch, setIsRNearVisionResultTouch] = useState("");

 // ==============right eye state end and left eye start=========================

  const [isUnAddedNumbTouch, setIsUnAddedNumbTouch] = useState("")
  const [isGlassesTouch, setisGlassesTouch] = useState("");
  const [isSphTouch, setIsSphTouch] = useState("")
  const [isCylTouch, setIsCylTouch] = useState('');
  const [isAxisTouch, setIsAxisTouch] = useState("");
  const [isVresultTouch, setIsVresultTouch] = useState("")
  const [isNearVisionTouch, setIsNearVisionTouch] = useState("");
  const [isNearVisionResultTouch, setIsNearVisionResultTouch]=useState("")


  const [isRNearVisionTouch, setIsRNearVisionTouch] = useState("");
  const [isRVisionResultTouch, setIsRVisionResultTouch]=useState("")

  
  const [eyePresureTouch, setEyePresureTouch] = useState("");
  const [rEyePresureTouch, setREyePresureTouch] = useState("");
  
  const {
    hasError: hasErrorEyePresure,
    enterValue: enterValueEyePresure,
    message: EyePresureMessage,
    onChangeHandler: onChangeHandlerEyePresure,
    reset: resetEyePresure,
    isTouch: isTouchEyePresure,
    onBlurHandler: onBlurHandlerEyePresure,
  } = useInput(isEyePresure, setNetworkError, setEyePresureTouch);

  const {
    hasError: hasErrorREyePresure,
    enterValue: enterValueREyePresure,
    message: REyePresureMessage,
    onChangeHandler: onChangeHandlerREyePresure,
    reset: resetREyePresure,
    isTouch: isTouchREyePresure,
    onBlurHandler: onBlurHandlerREyePresure,
  } = useInput(isREyePresure, setNetworkError, setREyePresureTouch);

  const {
    hasError: hasErrorLeftEyeVision,
    enterValue: enterValueLeftEyeVision,
    message: LeftEyeVisionMessage,
    onChangeHandler: onChangeHandlerLeftEyeVision,
    reset: resetLeftEyeVision,
    isTouch: isTouchLeftEyeVision,
    onBlurHandler: onBlurHandlerLeftEyeVision,
  } = useInput(isLeftEyeVision, setNetworkError, );

  
  // ============================================right eye start=========================================
  const {
    hasError: hasErrorisRUnAddedNumb,
    enterValue: enterValueisRUnAddedNumb,
    message: isRUnAddedNumbMessage,
    onChangeHandler: onChangeHandlerisRUnAddedNumb,
    reset: resetisRUnAddedNumb,
    isTouch: isTouchisRUnAddedNumb,
    onBlurHandler: onBlurHandlerisRUnAddedNumb
  } = useInput(isRUnAddedNumb, setNetworkError, setIsRUnAddedNumbTouch);

  const {
    hasError: hasErrorisRGlasses,
    enterValue: enterValueisRGlasses,
    message: isRGlassesMessage,
    onChangeHandler: onChangeHandlerisRGlasses,
    reset: resetisRGlasses,
    isTouch: isTouchisRGlasses,
    onBlurHandler: onBlurHandlerisRGlasses
  } = useInput(isRGlasses, setNetworkError, setisRGlassesTouch);

  const {
    hasError: hasErrorisRSph,
    enterValue: enterValueisRSph,
    message: isRSphMessage,
    onChangeHandler: onChangeHandlerisRSph,
    reset: resetisRSph,
    isTouch: isTouchisRSph,
    onBlurHandler: onBlurHandlerisRSph
  } = useInput(isRSph, setNetworkError, setIsRSphTouch);

  const {
    hasError: hasErrorisRCyl,
    enterValue: enterValueisRCyl,
    message: isRCylMessage,
    onChangeHandler: onChangeHandlerisRCyl,
    reset: resetisRCyl,
    isTouch: isTouchisRCyl,
    onBlurHandler: onBlurHandlerisRCyl
  } = useInput(isRCyl, setNetworkError, setIsRCylTouch);

  const {
    hasError: hasErrorisRAxis,
    enterValue: enterValueisRAxis,
    message: isRAxisMessage,
    onChangeHandler: onChangeHandlerisRAxis,
    reset: resetisRAxis,
    isTouch: isTouchisRAxis,
    onBlurHandler: onBlurHandlerisRAxis
  } = useInput(isRAxis, setNetworkError, setIsRAxisTouch);

  const {
    hasError: hasErrorisRVresult,
    enterValue: enterValueisRVresult,
    message: isRVresultMessage,
    onChangeHandler: onChangeHandlerisRVresult,
    reset: resetisRVresult,
    isTouch: isTouchisRVresult,
    onBlurHandler: onBlurHandlerisRVresult
  } = useInput(isRVresult, setNetworkError, setIsRVresultTouch);

  const {
    hasError: hasErrorisRNearVision,
    enterValue: enterValueisRNearVision,
    message: isRNearVisionMessage,
    onChangeHandler: onChangeHandlerisRNearVision,
    reset: resetisRNearVision,
    isTouch: isTouchisRNearVision,
    onBlurHandler: onBlurHandlerisRNearVision
  } = useInput(isRNearVision, setNetworkError, setIsRVisionTouch)

  const {
    hasError: hasErrorisRNearVisionResult,
    enterValue: enterValueisRNearVisionResult,
    message: isRNearVisionMessageResult,
    onChangeHandler: onChangeHandlerisRNearVisionResult,
    reset: resetisRNearVisionResult,
    isTouch: isTouchisRNearVisionResult,
    onBlurHandler: onBlurHandlerisRNearVisionResult
  } = useInput(isRNearVisionResult, setNetworkError, setIsRNearVisionResultTouch)

// ====================================================
  const {
    hasError: hasErrorisRVisionResult,
    enterValue: enterValueisRVisionResult,
    message: isRVisionMessageResult,
    onChangeHandler: onChangeHandlerisRVisionResult,
    reset: resetisRVisionResult,
    isTouch: isTouchisRVisionResult,
    onBlurHandler: onBlurHandlerisRVisionResult
  } = useInput(isRVisionResult, setNetworkError, )

  const {
    hasError: hasErrorRightEyeVision,
    enterValue: enterValueRightEyeVision,
    message: RightEyeVisionMessage,
    onChangeHandler: onChangeHandlerRightEyeVision,
    reset: resetRightEyeVision,
    isTouch: isTouchRightEyeVision,
    onBlurHandler: onBlurHandlerRightEyeVision,
  } = useInput(isRightEyeVision, setNetworkError, );

  // =======================================Left eye==========================================
  const {
    hasError: hasErrorisUnAddedNumb,
    enterValue: enterValueisUnAddedNumb,
    message: isUnAddedNumbMessage,
    onChangeHandler: onChangeHandlerisUnAddedNumb,
    reset: resetisUnAddedNumb,
    isTouch: isTouchisUnAddedNumb,
    onBlurHandler: onBlurHandlerisUnAddedNumb
  } = useInput(isUnAddedNumb, setNetworkError, setIsUnAddedNumbTouch);

  const {
    hasError: hasErrorisGlasses,
    enterValue: enterValueisGlasses,
    message: isGlassesMessage,
    onChangeHandler: onChangeHandlerisGlasses,
    reset: resetisGlasses,
    isTouch: isTouchisGlasses,
    onBlurHandler: onBlurHandlerisGlasses
  } = useInput(isGlasses, setNetworkError, setisGlassesTouch);

  const {
    hasError: hasErrorisSph,
    enterValue: enterValueisSph,
    message: isSphMessage,
    onChangeHandler: onChangeHandlerisSph,
    reset: resetisSph,
    isTouch: isTouchisSph,
    onBlurHandler: onBlurHandlerisSph
  } = useInput(isSph, setNetworkError, setIsSphTouch);

  const {
    hasError: hasErrorisCyl,
    enterValue: enterValueisCyl,
    message: isCylMessage,
    onChangeHandler: onChangeHandlerisCyl,
    reset: resetisCyl,
    isTouch: isTouchisCyl,
    onBlurHandler: onBlurHandlerisCyl
  } = useInput(isCyl, setNetworkError, setIsCylTouch);

  const {
    hasError: hasErrorisAxis,
    enterValue: enterValueisAxis,
    message: isAxisMessage,
    onChangeHandler: onChangeHandlerisAxis,
    reset: resetisAxis,
    isTouch: isTouchisAxis,
    onBlurHandler: onBlurHandlerisAxis
  } = useInput(isAxis, setNetworkError, setIsAxisTouch);

  const {
    hasError: hasErrorisVresult,
    enterValue: enterValueisVresult,
    message: isVresultMessage,
    onChangeHandler: onChangeHandlerisVresult,
    reset: resetisVresult,
    isTouch: isTouchisVresult,
    onBlurHandler: onBlurHandlerisVresult
  } = useInput(isVresult, setNetworkError, setIsVresultTouch);

  const {
    hasError: hasErrorisNearVision,
    enterValue: enterValueisNearVision,
    message: isNearVisionMessage,
    onChangeHandler: onChangeHandlerisNearVision,
    reset: resetisNearVision,
    isTouch: isTouchisNearVision,
    onBlurHandler: onBlurHandlerisNearVision
  } = useInput(isNearVision, setNetworkError, setIsNearVisionTouch);

  const {
    hasError: hasErrorisNearVisionResult,
    enterValue: enterValueisNearVisionResult,
    message: isNearVisionResultMessage,
    onChangeHandler: onChangeHandlerisNearVisionResult,
    reset: resetisNearVisionResult,
    isTouch: isTouchisNearVisionResult,
    onBlurHandler: onBlurHandlerisNearVisionResult
  } = useInput(isNearVisionResult, setNetworkError, setIsNearVisionResultTouch);
  // ============================================left eye end=========================================


  useEffect(() => {
    setSuccessMessage("");
  }, [addVisionDetail]);


  useEffect(() => {
    setNetworkError("");
    setIsRUnAddedNumbTouch("");
    setisRGlassesTouch("");
    setIsRSphTouch("");
    setIsRCylTouch(""); 
    setIsRAxisTouch("");
    setIsRVresultTouch("");
    setIsRVisionTouch("")
    setIsRNearVisionResultTouch("");
    // =========left eye ==============
    setIsUnAddedNumbTouch("");
    setisGlassesTouch("");
    setIsSphTouch("");
    setIsCylTouch("");
    setIsAxisTouch("");
    setIsVresultTouch("");
    setIsNearVisionTouch("");
    setIsNearVisionResultTouch("")


    setEyePresureTouch("");
    setIsRNearVisionTouch("");


  }, [enterValueisRUnAddedNumb,enterValueisRGlasses,enterValueisRSph,enterValueisRCyl,
    enterValueisRAxis,enterValueisRVresult,enterValueisRNearVision, enterValueisRNearVisionResult, 
    enterValueisUnAddedNumb, enterValueisGlasses, enterValueisSph,enterValueisCyl,
    enterValueisAxis,enterValueisVresult,enterValueisNearVision,enterValueisNearVisionResult,
    enterValueEyePresure, enterValueLeftEyeVision, enterValueRightEyeVision]);

  const addOptoPetientDetails = async () => {
    setLoading(true);
    setNetworkError("");
    setLoadingMessage("Adding an Entry...");
    if (selectedItemsCheckBox.length < 1) {
      setSelectedItemsCheckBoxError("Please Select Patient Complaints!");
      setLoading(false);
      return false;
    }


    if (
      hasErrorisRUnAddedNumb === true ||
      hasErrorisRGlasses === true ||
      hasErrorisRSph === true ||
      hasErrorisRCyl === true ||
      hasErrorisRAxis === true ||
      hasErrorisRVresult === true ||
      hasErrorisRNearVision === true||
      hasErrorisRNearVisionResult === true ||
      // ================left eye start====================
      hasErrorisUnAddedNumb === true ||
      hasErrorisGlasses === true ||
      hasErrorisSph === true ||
      hasErrorisCyl === true ||
      hasErrorisAxis === true ||
      hasErrorisVresult === true ||
      hasErrorisNearVision === true ||
      hasErrorisNearVisionResult === true||
      
      hasErrorisRVisionResult === true 
    ) {
      setLoading(false);
      setLoadingMessage("");
      setNetworkError("Please Fill All Fields Appropriately 90!");
      return false;
    } else if (
      !isTouchisRUnAddedNumb ||
      !isTouchisRGlasses ||
      !isTouchisRSph ||
      !isTouchisRCyl ||
      !isTouchisRAxis ||
      !isTouchisRVresult ||
      !isTouchisRNearVision ||
      !isTouchisRNearVisionResult ||
// ==============left eye start===========
      !isTouchisUnAddedNumb ||
      !isTouchisGlasses ||
      !isTouchisSph ||
      !isTouchisCyl ||
      !isTouchisAxis ||
      !isTouchisVresult ||
      !isTouchisNearVision ||
      !isTouchisNearVisionResult 

      // !isTouchisRNearVision ||
      // !isTouchEyePresure ||
      // !isTouchisRVisionResult

    ) {
      if (!isTouchisRUnAddedNumb) {
        setIsRUnAddedNumbTouch("Please Right Un-Added Number!");
      }
      if (!isTouchisRGlasses) {
        setisRGlassesTouch("Please Right Glasses Number!");
      }
      if (!isTouchisRSph) {
        setIsRSphTouch("Please Right Sph Number!");
      }
      if (!isTouchisRCyl) {
        setIsRCylTouch("Please Right Cyl Number!");
      }
      if (!isTouchisRAxis) {
        setIsRAxisTouch("Please Right Enter Axis!");
      }
      if (!isTouchisRVresult) {
        setIsRVresultTouch("Please Enter Right Vision Result!");
      }
      if (!isTouchisRNearVision) {
        setIsRVisionTouch("Please Enter Vision Result!");
      }
      if (!isTouchisRNearVisionResult) {
        setIsRNearVisionResultTouch("Please Enter Right Eye Near Vision Result!");
      }
    // =========================left eye start===========================
      if (!isTouchisUnAddedNumb) {
        setIsUnAddedNumbTouch("Please Left Un-Added Number!");
      }
      if (!isTouchisGlasses) {
        setisGlassesTouch("Please Left Glasses Number!");
      }
      if (!isTouchisSph) {
        setisGlassesTouch("Please Left Sph Number!");
      }
      if (!setIsCylTouch) {
        setIsCylTouch("Please Enter Left Cyl Number!");
      }
      if (!isTouchisAxis) {
        setIsAxisTouch("Please Enter Left Axis!");
      }
      if (!isTouchisVresult) {
        setIsVresultTouch("Please Enter Left Vision Result!");
      }
      if (!isTouchisNearVision) {
        setIsNearVisionTouch("Please Enter Left Near Vision!");
      }
      if (!isTouchisNearVisionResult) {
        setIsNearVisionResultTouch("Please Enter Right Eye Vision Result!");
      }
      
      if (!isTouchisRVisionResult) {
        setIsRNearVisionResultTouch("Please Enter Right Eye Vision Result!");
      }
      
     
    
      setLoading(false);
      setLoadingMessage("");
      setNetworkError("Please Fill All Fields Appropriately!");
      return false;
    }
    if (!leftEyeSyringing) {
      setLeftEyeSyringingError("Please Select Left Eye Syringing!");
      setLoading(false);
      return false;
    }
    if (!rightEyeSyringing) {
      setRightEyeSyringingError("Please Select Right Eye Syringing!");
      setLoading(false);
      return false;
    }
    if (!visionType) {
      setVisionTypeError("Please Select Vision Type!");
      setLoading(false);
      return false;
    }
    try {

      console.log(editProperDetail, "624")
      // const token = getAdminToken();
      const body = {
        patientId: editProperDetail?._id,
        payId: editProperDetail?.payId,
        fName: editProperDetail?.fName,
        lName: editProperDetail?.lName,
        contact: editProperDetail?.contact,
        gender: editProperDetail?.gender,
        age: editProperDetail?.age,
        patientComplaint: selectedItemsCheckBox,
        leftEyeVision: {
          unaided: enterValueisUnAddedNumb,
          withGlasses: enterValueisGlasses,
          sph: enterValueisSph,
          cyl: enterValueisCyl,
          axis: enterValueisAxis,
          visionResult: enterValueisVresult,
          nearVision: enterValueisNearVision,
          nearVisionResult:enterValueisNearVisionResult
        },
        rightEyeVision: {
          unaided: enterValueisRUnAddedNumb,
          withGlasses: enterValueisRGlasses,
          sph: enterValueisRSph,
          cyl: enterValueisRCyl,
          axis: enterValueisRAxis,
          visionResult: enterValueisRVresult,
          nearVision: enterValueisRNearVision,
          nearVisionResult:enterValueisRNearVisionResult
        },
        visionType: visionType,
        syringDone: {
          left: leftEyeSyringing,
          right: rightEyeSyringing
        },
        eyePressure: {
          left: enterValueEyePresure,
          right: enterValueREyePresure,
        }

      };
      console.log(body, "582");

      // const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch("/optometrist/assessment_detail", "post", body);
      if (response.ok === false) {
        setLoading(false);
        setNetworkError(response.data.message);
      }
      setLoading(false);
      setSuccessMessage(response.data.message);
      setAddVisionDetail(false);
      toast.success("Successfully Update Patient Details!");
      resetfield();
      // resetEyePresure();
      // resetLeftEyeVision();
      // resetRightEyeVision();
      // setLeftEyeVision("");
      // setRightEyeVision("");
      // setVisionType("");
      // setLeftEyeSyringing("");
      // setRightEyeSyringing("");
      // setLeftEyeVisionError("");
      // setRightEyeVisionError("");
      // setVisionTypeError("");
      // setLeftEyeSyringingError("");
      // setRightEyeSyringingError("");
      // setSelectedItemsCheckBox([])
      // setSelectedItemsCheckBoxError("");
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    }
  };

  const addHandler = () => {
    addOptoPetientDetails();
  };


  const resetfield = () => {
    setNetworkError("");
    resetEyePresure();
    resetLeftEyeVision();
    resetRightEyeVision();

    setEyePresureTouch("");

    setLeftEyeVision("");
    setRightEyeVision("");
    setVisionType("");
    setLeftEyeSyringing("");
    setRightEyeSyringing("");
    setLeftEyeVisionError("");
    setRightEyeVisionError("");
    setVisionTypeError("");
    setLeftEyeSyringingError("");
    setRightEyeSyringingError("");
    setSelectedItemsCheckBox([])
    setSelectedItemsCheckBoxError("");
    // ====================lfet right============
    resetisUnAddedNumb("");
    resetisGlasses("");
    resetisSph("");
    resetisCyl("");
    resetisAxis("");
    resetisVresult("");
    resetisNearVision("");

    resetisRUnAddedNumb("");
    resetisRGlasses("");
    resetisRSph("");
    resetisRCyl("");
    resetisRAxis("");
    resetisRVresult("");
    resetisRNearVision("");
  };

  return (
    <Transition.Root show={addVisionDetail} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setAddVisionDetail}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex mt-10 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" max-[400px]:w-[50%] max-[640px]:w-[90%] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 lg:w-[85%] sm:w-[75%] md:w-[85%] xl:w-[80%]">
                <div className="flex flex-row justify-between items-center">
                  <div className=" px-4 text-start  bg-white">
                    <h2 className="text-gray-600 text-lg font-semibold    ">
                      Add Assessment: ({editProperDetail?.fName})
                    </h2>
                  </div>
                  <div className="px-4 py-3 sm:px-6  ">
                    <button
                      type="button"
                      className="w-full sm:w-auto justify-center rounded-md border border-transparent bg-white px-1 py-1 text-base font-semibold text-gray-900  focus:outline-none  "
                      onClick={() => {
                        setAddVisionDetail(false);
                        resetfield();
                      }}
                      ref={cancelButtonRef}
                    >
                      <XMarkIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>


                <div className="ml-[7%] mb-6">
                  <div className=" w-[92%] mt-4 bg-gray-50 px-4 pt-4 rounded-lg ">
                    <div className="grid grid-cols-1  sm:grid-cols-4 gap-1 text-sm">
                      <p >
                        Name:{" "}<span className="font-semibold">{editProperDetail?.fName} {" "} {editProperDetail?.lName}</span>
                      </p>
                      <p>
                        Age:{" "}<span className="font-semibold">{editProperDetail?.age}</span>
                        {/* {editProperDetail?._id} */}
                      </p>
                      <p>
                        Gender:{" "}<span className="font-semibold">{editProperDetail?.gender}</span>
                      </p>
                      <p>
                        Contact:{" "}<span className="font-semibold">{editProperDetail?.contact}</span>

                      </p>
                    </div>
                  </div>
                  {/* Complains */}
                  <h1 className="w-[92%] block text-base font-medium text-center leading-20 text-gray-900 mt-4">
                    -- Petient's Complaints --
                  </h1>
                  <div className="w-[92%] gap-x-4 grid grid-cols-1 max-[640px]:grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6">

                    {visionCheckBoxList?.map((item, index) => (
                      <div className="mt-3" key={index}>
                        <div className="flex items-center mb-2">
                          <input
                            type="checkbox"
                            id={`checkbox-${index}`}
                            value={item}
                            checked={selectedItemsCheckBox.includes(item)} // Set checked if item is in selectedItems
                            onChange={handleCheckboxChange}
                            className="mr-2"
                          />
                          <label htmlFor={`checkbox-${index}`}>{item}</label>
                        </div>
                      </div>
                    ))}

                    {/* <div className="mt-4">
                      <h3>Selected Items:</h3>
                      <ul>
                        {selectedItemsCheckBox.map((selectedItem, index) => (
                          <li key={index}>{selectedItem}</li>
                        ))}
                      </ul>
                    </div> */}
                  </div>
                  {selectedItemsCheckBoxError && (
                    <div className="flex ml-[0.5px] text-center">
                      <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                      <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                        {selectedItemsCheckBoxError}
                      </p>
                    </div>
                  )}
                  <div className="w-[92%] gap-x-2 grid grid-cols-1 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-2  px-4 pb-4  rounded-lg  shadow-md ">
                  <div>
                      <h1 className="w-[92%] block text-base font-medium text-center leading-20 text-gray-900 mt-4">
                        -- Right Eye Assessment --
                      </h1>
                      <div className="w-[92%] gap-x-4 grid grid-cols-1 max-[640px]:grid-cols-1 sm:grid-cols-2 md:grid-cols-2">
                        {/*Right Un-Added */}
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"Un-Added"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="Un-Added"
                            id="Un-Added"
                            name="Un-Added"
                            enterValue={enterValueisRUnAddedNumb}
                            onChangeHandler={onChangeHandlerisRUnAddedNumb}
                            hasError={hasErrorisRUnAddedNumb}
                            errorMessage={isRUnAddedNumbMessage}
                            isTouch={isRUnAddedNumbTouch}
                            setIsTouch={setIsRUnAddedNumbTouch}
                            onBlurHandler={onBlurHandlerisRUnAddedNumb}
                          />

                        </div>
                        {/*Right with glasses */}
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"c̅ glasses"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="c̅ Glasses"
                            id="with_glasses"
                            name="with_glasses"
                            enterValue={enterValueisRGlasses}
                            onChangeHandler={onChangeHandlerisRGlasses}
                            hasError={hasErrorisRGlasses}
                            errorMessage={isRGlassesMessage}
                            isTouch={isRGlassesTouch}
                            setIsTouch={setisRGlassesTouch}
                            onBlurHandler={onBlurHandlerisRGlasses}
                          />

                        </div>
                        {/*Right Sph */}
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"Sph"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="sph"
                            id="sph"
                            name="sph"
                            enterValue={enterValueisRSph}
                            onChangeHandler={onChangeHandlerisRSph}
                            hasError={hasErrorisRSph}
                            errorMessage={isRSphMessage}
                            isTouch={isRSphTouch}
                            setIsTouch={setIsRSphTouch}
                            onBlurHandler={onBlurHandlerisRSph}
                          />

                        </div>
                        {/*Right  Cyl */}
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"Cyl"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="Cyl Number"
                            id="cyl"
                            name="cyl"
                            enterValue={enterValueisRCyl}
                            onChangeHandler={onChangeHandlerisRCyl}
                            hasError={hasErrorisRCyl}
                            errorMessage={isRCylMessage}
                            isTouch={isRCylTouch}
                            setIsTouch={setIsRCylTouch}
                            onBlurHandler={onBlurHandlerisRCyl}
                          />

                        </div>
                        {/*Right Axis */}
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"Axis"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="Enter Axis"
                            id="axis"
                            name="axis"
                            enterValue={enterValueisRAxis}
                            onChangeHandler={onChangeHandlerisRAxis}
                            hasError={hasErrorisRAxis}
                            errorMessage={isRAxisMessage}
                            isTouch={isRAxisTouch}
                            setIsTouch={setIsRAxisTouch}
                            onBlurHandler={onBlurHandlerisRAxis}
                          />

                        </div>
                        {/*Right vision_result */}
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"V-Result"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="Enter  Result"
                            id="v_result"
                            name="v_result"
                            enterValue={enterValueisRVresult}
                            onChangeHandler={onChangeHandlerisRVresult}
                            hasError={hasErrorisRVresult}
                            errorMessage={isRVresultMessage}
                            isTouch={isRVresultTouch}
                            setIsTouch={setIsRVresultTouch}
                            onBlurHandler={onBlurHandlerisRVresult}
                          />

                        </div>
                         {/*Right  near-vision */}
                         <div className="mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {"Near Vision"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Near Vision"
                          id="near_vision"
                          name="near_vision"
                          enterValue={enterValueisRNearVision}
                          onChangeHandler={onChangeHandlerisRNearVision}
                          hasError={hasErrorisRNearVision}
                          errorMessage={isRNearVisionMessage}
                          isTouch={isRVisionTouch}
                          setIsTouch={setIsRVisionTouch}
                          onBlurHandler={onBlurHandlerisRNearVision}
                        />
                      </div>
                      {/* Right Near Vision Result */}
                      <div className="mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {"Near Vision Result"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Near Vision Result"
                          id="near_vision_result"
                          name="near_vision_result"
                          enterValue={enterValueisRNearVisionResult}
                          onChangeHandler={onChangeHandlerisRNearVisionResult}
                          hasError={hasErrorisRNearVisionResult}
                          errorMessage={isRNearVisionMessageResult}
                          isTouch={isRNearVisionResultTouch}
                          setIsTouch={setIsRNearVisionResultTouch}
                          onBlurHandler={onBlurHandlerisRNearVisionResult}
                        />
                      </div>
                      </div>
                    </div>
                    
                      {/* lEFT Eye */}
                      <div>
                      <h1 className="w-[92%] block text-base font-medium text-center leading-20 text-gray-900 mt-4">
                        -- Left Eye Assessment --
                      </h1>
                      <div className="w-[92%] gap-x-4 grid grid-cols-1 max-[640px]:grid-cols-1 sm:grid-cols-2 md:grid-cols-2">
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"Un-Added"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="Un-Added"
                            id="Un-Added"
                            name="Un-Added"
                            enterValue={enterValueisUnAddedNumb}
                            onChangeHandler={onChangeHandlerisUnAddedNumb}
                            hasError={hasErrorisUnAddedNumb}
                            errorMessage={isUnAddedNumbMessage}
                            isTouch={isUnAddedNumbTouch}
                            setIsTouch={setIsUnAddedNumbTouch}
                            onBlurHandler={onBlurHandlerisUnAddedNumb}
                          />

                        </div>

                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"c̅ glasses"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="c̅ Glasses"
                            id="with_glasses"
                            name="with_glasses"
                            enterValue={enterValueisGlasses}
                            onChangeHandler={onChangeHandlerisGlasses}
                            hasError={hasErrorisGlasses}
                            errorMessage={isGlassesMessage}
                            isTouch={isGlassesTouch}
                            setIsTouch={setisGlassesTouch}
                            onBlurHandler={onBlurHandlerisGlasses}
                          />

                        </div>
                        {/* Sph */}
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"Sph"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="sph"
                            id="sph"
                            name="sph"
                            enterValue={enterValueisSph}
                            onChangeHandler={onChangeHandlerisSph}
                            hasError={hasErrorisSph}
                            errorMessage={isSphMessage}
                            isTouch={isSphTouch}
                            setIsTouch={setIsSphTouch}
                            onBlurHandler={onBlurHandlerisSph}
                          />

                        </div>
                        {/* Cyl */}
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"Cyl"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="Cyl Number"
                            id="cyl"
                            name="cyl"
                            enterValue={enterValueisCyl}
                            onChangeHandler={onChangeHandlerisCyl}
                            hasError={hasErrorisCyl}
                            errorMessage={isCylMessage}
                            isTouch={isCylTouch}
                            setIsTouch={setIsCylTouch}
                            onBlurHandler={onBlurHandlerisCyl}
                          />

                        </div>
                        {/* Axis */}
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"Axis"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="Enter Axis"
                            id="axis"
                            name="axis"
                            enterValue={enterValueisAxis}
                            onChangeHandler={onChangeHandlerisAxis}
                            hasError={hasErrorisAxis}
                            errorMessage={isAxisMessage}
                            isTouch={isAxisTouch}
                            setIsTouch={setIsAxisTouch}
                            onBlurHandler={onBlurHandlerisAxis}
                          />

                        </div>
                        {/* vision_result */}
                        <div className="mt-3">
                          <Input
                            type="text"
                            label={
                              <>
                                {"V-Result"}
                                <span className="text-red-600 font-bold"> *</span>
                              </>
                            }
                            placeholder="Enter Vision Result"
                            id="vision_result"
                            name="vision_result"
                            enterValue={enterValueisVresult}
                            onChangeHandler={onChangeHandlerisVresult}
                            hasError={hasErrorisVresult}
                            errorMessage={isVresultMessage}
                            isTouch={isVresultTouch}
                            setIsTouch={setIsVresultTouch}
                            onBlurHandler={onBlurHandlerisVresult}
                          />

                        </div>
                        {/* near-vision */}
                        <div className="mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {"Near Vision"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Near Vision"
                          id="near_vision"
                          name="near_vision"
                          enterValue={enterValueisNearVision}
                          onChangeHandler={onChangeHandlerisNearVision}
                          hasError={hasErrorisNearVision}
                          errorMessage={isNearVisionMessage}
                          isTouch={isNearVisionTouch}
                          setIsTouch={setIsNearVisionTouch}
                          onBlurHandler={onBlurHandlerisNearVision}
                        />

                      </div>
                      {/* nearVisionResult */}
                      <div className="mt-3">
                        <Input
                          type="text"
                          label={
                            <>
                              {"Near Vision Result"}
                              <span className="text-red-600 font-bold"> *</span>
                            </>
                          }
                          placeholder="Near Vision Result"
                          id="near_visionresult"
                          name="near_visionresult"
                          enterValue={enterValueisNearVisionResult}
                          onChangeHandler={onChangeHandlerisNearVisionResult}
                          hasError={hasErrorisNearVisionResult}
                          errorMessage={isNearVisionResultMessage}
                          isTouch={isNearVisionResultTouch}
                          setIsTouch={setIsNearVisionResultTouch}
                          onBlurHandler={onBlurHandlerisNearVisionResult}
                        />

                      </div>
                      </div>
                      
                    </div>
                  </div>




                  <div className="w-[92%] gap-x-4 grid grid-cols-1 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-3">
                    <div className="mt-3">
                      <div className="flex items-center justify-between mb-2">
                        <label
                          htmlFor="gender"
                          className="block text-sm font-bold leading-6 text-gray-900"
                        >
                          Vision Type
                          <span className="text-red-600 font-bold"> *</span>
                        </label>
                      </div>

                      <select
                        value={visionType}
                        onChange={handleVisionTypeChange}
                        className="block w-full rounded-md border border-gray-300 py-[11px] text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium"
                      >
                        <option value="" disabled>Select Vision Type</option>
                        <option value="myopia">Myopia</option>
                        <option value="hyperopia">Hyperopia</option>
                        <option value="astigmatism">Astigmatism</option>
                        <option value="presbyopia">Presbyopia</option>
                        <option value="Normal">Normal</option>
                      </select>
                      {visionTypeError && (
                        <div className="flex ml-[0.5px]">
                          <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                          <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                            {visionTypeError}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <Input
                        type="text"
                        label={
                          <>
                            {"Left Eye Presure"}
                            {/* <span className="text-red-600 font-bold"> *</span> */}
                          </>
                        }
                        placeholder="Enter Left Eye Presure"
                        id="leye_presure"
                        name="leye_presure"
                        enterValue={enterValueEyePresure}
                        onChangeHandler={onChangeHandlerEyePresure}
                        hasError={hasErrorEyePresure}
                        errorMessage={EyePresureMessage}
                        isTouch={eyePresureTouch}
                        setIsTouch={setEyePresureTouch}
                        onBlurHandler={onBlurHandlerEyePresure}
                      />

                    </div> <div className="mt-3">
                      <Input
                        type="text"
                        label={
                          <>
                            {"Right Eye Presure"}
                            <span className="text-red-600 font-bold"> *</span>
                          </>
                        }
                        placeholder="Enter Right Eye Presure"
                        id="reye_presure"
                        name="reye_presure"
                        enterValue={enterValueREyePresure}
                        onChangeHandler={onChangeHandlerREyePresure}
                        hasError={hasErrorREyePresure}
                        errorMessage={REyePresureMessage}
                        isTouch={rEyePresureTouch}
                        setIsTouch={setREyePresureTouch}
                        onBlurHandler={onBlurHandlerREyePresure}
                      />

                    </div>
                  </div>

                  <h1 className="w-[92%] block text-base font-medium text-center leading-20 text-gray-900 mt-4">
                    -- Syringing --
                  </h1>
                  <div className="w-[92%] gap-x-4 grid grid-cols-1 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-2">
                    <div className="mt-3">

                      <div className="flex items-center justify-between mb-2">
                        <label
                          htmlFor="gender"
                          className="block text-sm font-bold leading-6 text-gray-900"
                        >
                          Left Eye
                          <span className="text-red-600 font-bold"> *</span>
                        </label>
                      </div>

                      <select
                        value={leftEyeSyringing}
                        onChange={handleLeftEyeSyringChange}
                        className="block w-full rounded-md border border-gray-300 py-[11px] text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium"
                      >
                        <option value="">Select Result</option>
                        {syringingOptions?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {leftEyeSyringingError && (
                        <div className="flex ml-[0.5px]">
                          <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                          <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                            {leftEyeSyringingError}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <div className="flex items-center justify-between mb-2">
                        <label
                          htmlFor="gender"
                          className="block text-sm font-bold leading-6 text-gray-900"
                        >
                          Right Eye
                          <span className="text-red-600 font-bold"> *</span>
                        </label>
                      </div>

                      <select
                        value={rightEyeSyringing}
                        onChange={handleRightEyeSyringChange}
                        className="block w-full rounded-md border border-gray-300 py-[11px] text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium"
                      >
                        <option value="">Select Result</option>
                        {syringingOptions?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {rightEyeSyringingError && (
                        <div className="flex ml-[0.5px]">
                          <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                          <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                            {rightEyeSyringingError}
                          </p>
                        </div>
                      )}

                    </div>

                  </div>

                </div>


                <div className="text-center">
                  {loading && (
                    <strong className="text-blue-800 text-sm  text-center">
                      {loadingMessage}
                    </strong>
                  )}
                  {networkError && (
                    <strong className="text-red-600 text-sm  text-center">
                      {networkError}
                    </strong>
                  )}
                  {successMessage && (
                    <strong className="text-green-800 text-sm  text-center">
                      {successMessage}
                    </strong>
                  )}
                </div>
                <div className=" bg-gray-50 px-4 py-3 mb-4 mt-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    disabled={loading}
                    className="inline-flex ml-[4%] w-[92%] justify-center rounded-md  px-3 py-2 text-sm font-medium text-white shadow-sm sm:ml-3 sm:w-auto mt-[15px] bg-[#10a659] hover:bg-[#0d5781] "
                    onClick={() => {
                      addHandler();
                    }}
                  >
                    {loading === true ?
                      (
                        <MoonLoader
                          color="#fff"
                          loading={loading}
                          size={20}
                          className="mx-1"
                        />
                      ) : (

                        <>Add</>
                      )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex ml-[4%] w-[92%] justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      resetfield();
                      setAddVisionDetail(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}