/** @format */

const CryptoJS = require("crypto-js");

export const APP_KEY = "beenai_clinic";

export const encrypt = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    APP_KEY
  ).toString();

  return encodeURIComponent(encrypted);
};

export const decrypt = (encryptedData) => {
  const decodedData = decodeURIComponent(encryptedData);
  const decryptedData = CryptoJS.AES.decrypt(decodedData, APP_KEY).toString(
    CryptoJS.enc.Utf8
  );
  return JSON.parse(decryptedData);
};

export const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const year = d.getFullYear();
  return `${day}-${month}-${year}`;
};
// ====================================================vission details array================================================
export const visionOptions = [
  "6/6",
  "6/9",
  "6/12",
  "6/18",
  "6/24",
  "6/36",
  "6/60",
  "5/60",
  "4/60",
  "3/60",
  "2/60",
  "1/60",
  "CFCF (Counting Fingers)",
  "HM (Hand Movement)",
  "PL (Perception of Light)",
  "NPL (No Perception of Light)",
];

export const complaintOptions = [
  { id: "blurry_vision", label: "Blurry Vision" },
  { id: "eye_strain", label: "Eye Strain" },
  { id: "headaches", label: "Headaches" },
  { id: "double_vision", label: "Double Vision" },
  { id: "dry_eye", label: "Dry Eye" },
  { id: "eye_pain", label: "Eye Pain" },
  { id: "light_sensitivity", label: "Light Sensitivity" },
  { id: "d_o_v", label: "D.O.V" },
  { id: "watering", label: "Watering" },
  { id: "discharge", label: "Discharge" },
  { id: "itching", label: "Itching" },
  { id: "redness", label: "Redness" },
  // { id: "routine_eye_check-up", label: "Routine" },
];
export const syringingOptions = [
  { id: "patent", label: "Patent" },
  { id: "partially_patent", label: "Partially Patent" },
  { id: "blocked", label: "Blocked" },
  { id: "Upper_patent_lower_blocked", label: "Upper patent, lower blocked" },
  { id: "Lower_patent_Upper_blocked", label: "Lower patent, Upper blocked" },
  { id: "not_performed", label: "Not Performed" },
];

export const visionCheckBoxList = [
  "Blurry Vision",
  "Eye Strain",
  "Headaches",
  "Double Vision",
  "Dry Eye",
  "Eye Pain",
  "Light Sensitivity",
  "D.O.V",
  "Watering",
  "Discharge",
  "Itching",
  "Redness",
];

// ====================================================vission details end here================================================

export const states_arr = new Array(
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Orissa",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttaranchal",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Pondicherry"
);

export const customerTitle = [
  "Dr",
  "Miss",
  "Mr",
  "Mrs",
  "Ms",
  "Pr",
  "Prof",
  "Rev",
];
