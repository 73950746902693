import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserProfileMenu from "../components/userNav/UserProfileMenu";
import logo from "../../assets/logo/logo_beenayi.png";

const Header = () => {
  let token = localStorage.getItem("beenai_token");

  useEffect(() => {
    token = localStorage.getItem("beenai_token");
  });

  return (
    <div >
      <header>
        <nav className={`navbar navbar-expand-lg`}>
          <div className="container-fluid">
            <a className="navbar-brand p-1" href="/">
              <img
                src={logo && logo}
                alt="Beenai_clinic"
                className="img-fluid"
              />
            </a>

            <div
              className={`collapse navbar-collapse `}
              id="navbarSupportedContent"
            >
              {/* <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                {token ? (
                  <li className="nav-item mt-[30px]">
                    <UserProfileMenu />
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link to="/" className="nav-link ">
                      Login
                    </Link>
                  </li>
                )}
              </ul> */}
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
