/** @format */

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import bookingPolicyReducer from "./features/bookingPolicy";

export default configureStore({
  reducer: {
    user: userReducer,
    policy: bookingPolicyReducer,
  },
});
