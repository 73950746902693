/** @format */

export const emailreg = /[a-z]+[a-z0-9]*\@[a-z]{5,12}\.[a-z]{2,3}/;

export const fullName = /^[a-zA-Z\s]+(?:\s[a-zA-Z\s]+)?$/;

export const numberreg = /^\d{10}$/;

export const numberReg = /\d+/;

export const feeReg = /^\d+(\.\d+)?$/;

export const pincodeNumber = /^\d{6}$/;

export const couponValueNumber = /^\d{2}$/;
