/** @format */

import {useEffect, Fragment, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {emailreg} from "../../../service/validations/validation";
import {useInput} from "../../../hook/input-hook";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {fetch} from "../../../service/utils";
import Input from "../input/Input.component";

const isEmail = (value) => {
  if (value.trim() === "") {
    return {validated: false, message: "**Please Enter Your Email**"};
  } else if (!emailreg.test(value)) {
    return {validated: false, message: "**Your Email Is Invalid**"};
  } else {
    return {validated: true, message: ""};
  }
};
export default function ForgotPwd({openForgotPwd, setOpenForgotPwd, role_id}) {
  const [networkError, setNetworkError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailTouchblur, setEmailTouchblur] = useState("");
  const [subError, setSubError] = useState("");
  const navigate = useNavigate();

  const {
    hasError: hasErrorEmail,
    enterValue: enterValueEmail,
    onChangeHandler: onChangeHandlerEmail,
    reset: resetEmail,
    message: emailMessage,
    isTouch: isTouchEmail,
    onBlurHandler: onBlurHandlerEmail,
  } = useInput(isEmail, setNetworkError, setEmailTouchblur);

  useEffect(() => {
    setSubError("");
    setEmailTouchblur("");
  }, [enterValueEmail]);

  const submitHandler = async (e) => {
    // e.preventDefault();
    setIsLoading(true);
    setNetworkError("");

    if (hasErrorEmail === true) {
      setSubError("fields");
      setIsLoading(false);
      return false;
    } else if (!isTouchEmail) {
      if (!isTouchEmail) {
        setEmailTouchblur("**Please Enter Your Email**");
      }

      setSubError("fields");
      setIsLoading(false);
      return false;
    }

    try {
      const response = await fetch("/auth/forgot-pwd", "post", {
        email: enterValueEmail,
        role_id,
      });
      setIsLoading(false);
      if (response && response.data.ok === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
        toast.error(response.data.message);
      }

      navigate("/");
      toast.success("Email Sent Successfully!");
      resetEmail();
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };
  return (
    <Transition.Root show={openForgotPwd} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        // initialFocus={cancelButtonRef}
        onClose={setOpenForgotPwd}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-medium leading-6 text-gray-900 mb-3"
                    >
                      Forgot Your Password
                    </Dialog.Title>

                    <Input
                      type="text"
                      label="Email"
                      placeholder="Enter Your Email"
                      id="email"
                      name="email"
                      enterValue={enterValueEmail}
                      onChangeHandler={onChangeHandlerEmail}
                      hasError={hasErrorEmail}
                      errorMessage={emailMessage}
                      isTouch={isEmailTouchblur}
                      setIsTouch={setEmailTouchblur}
                      onBlurHandler={onBlurHandlerEmail}
                    />
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-4 sm:flex sm:flex-row-reverse sm:px-6 ">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`inline-flex w-full justify-center rounded-md bg-[#134c49] px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto ${
                      isLoading ? "cursor-not-allowed" : ""
                    }`}
                    onClick={() => submitHandler()}
                  >
                    {isLoading ? "Sending..." : "Send"}{" "}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpenForgotPwd(false);
                      resetEmail();
                    }}
                  >
                    Cancel
                  </button>
                </div>

                {/* <div className=" text-sm text-center my-2">
                  {networkError && networkError ? (
                    <div style={{ extAlign: "center", color: "red" }}>
                      {networkError}
                    </div>
                  ) : subError && subError ? (
                    <div style={{ textAlign: "center", color: "red" }}>
                      {subError}
                    </div>
                  ) : null}
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
