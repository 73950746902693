import { Link } from "react-router-dom";
import errorImg from "../img/404-error-img.avif";
export default function ErrorPage() {
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 lg:px-8 mb-12">
        <div className="text-center">
          <p className="text-base font-semibold ">
            <img src={errorImg} alt="page404" className="w-full h-auto" />
          </p>

          <div className=" flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="bg-[#134c49] px-3.5 py-2.5 rounded-md   text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Go back home
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
