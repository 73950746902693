import React, { useEffect } from "react";
import { useState } from "react";
import DeleteRegistrationForm from "../../components/modals/DeleteModal";
import EditRegistrationForm from "../../components/modals/EditRegistrationForm";
import ForwardForm from "../../components/modals/ForwardForm";
import { fetch } from "../../../service/utils";
import toast from "react-hot-toast";
import { PencilIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import DeleteModal from "../../components/modals/DeleteModal";
import { Link } from "react-router-dom";

function ViewAll(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState("");
const [editPatientDetail, setEditPatientDetail]= useState("")
  const [getReceptionData, setGetReceptionData] = useState([]);
  // console.log("abcd", getReceptionData)
const [patientId, setPatientId]=useState();
  const [editRegFormOpen, setEditRegFormOpen] = useState(false);
  const [DeleteRegForm, setDeleteRegForm] = useState(false);
  const [forwardFormOpen, setForwardFormOpen] = useState(false);
  console.log(getReceptionData, "28");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPatient, setFilteredPatient] = useState([]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase() || "";
    setSearchQuery(query);

    const filtered =
      getReceptionData &&
      getReceptionData.length > 0 &&
      getReceptionData?.filter((pt) =>
        pt?.fname?.toLowerCase().includes(query)
      );
    setFilteredPatient(filtered);
  };

  const getReceptionDetails = async (e) => {
    setIsLoading(true);
    setNetworkError("");

    try {
      const response = await fetch("/reception/display-patients", "get", null);
      setIsLoading(false);
      if (response.data.success === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      } else {
        setGetReceptionData(response.data.data);
      }
    } catch (err) {
      setIsLoading(false);
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Something went wrong. Please try again later.";
      setNetworkError(errorMessage);
      toast.error(errorMessage);
      console.log(err);
    }
  };

  useEffect(() => {
    getReceptionDetails();
  }, []);
  return (
    <>
      <div className="text-center font-semibold leading-30 mt-2 " style={{ fontSize: "30px" }}></div>
      <section className="px-20 pt-4 pb-20">

        <div className="inline-block w-full  border-[1px] border-gray-300 overflow-auto">
          <div className="overflow-auto">
            <div className="flex min-w-full  items-center justify-between py-2 mb-3 bg-[#0d5781]">
              <div className="font-semibold text-lg px-3 text-white ">
                Patient Dashboard
              </div>

              <Link to="/registration" className="mr-10">
                <button
                  className="px-3 py-[6px]  text-base font-medium text-white bg-[#10a659] rounded-md shadow-md focus:outline-none focus:ring-2"

                >
                  Add Patient
                </button>
              </Link>
            </div>
            <div className="flex min-w-full items-center justify-end  mb-2">

              <div className="border-[1px] border-[#0d5781] w-[20%] rounded-[4px] mr-10">
                <input
                  type="text"
                  placeholder="Search Name, Contact, City..."
                  className="px-2 py-[5px] rounded-[4px] w-full "
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>


            </div>

            <table class="table table-hover ">
              <thead className="bg-[#0d5781] text-white">
                <tr>
                  <th scope="col" className="w-[3%] font-medium">
                    #
                  </th>
                  <th scope="col" className="w-[17%] font-medium">
                    Full Name
                  </th>
                  <th scope="col" className="w-[5%] text-center font-medium">
                    Age
                  </th>
                  <th scope="col" className="w-[7%] text-center font-medium">
                    Gender
                  </th>
                  <th scope="col" className="w-[13%]  font-medium">
                    Contact
                  </th>
                  <th scope="col" className="w-[20%]  font-medium">
                    Address
                  </th>
                  <th scope="col" className="w-[10%] text-center  font-medium">
                    City
                  </th>
                  <th scope="col" className="w-[10%]  text-center font-medium">
                    Payment
                  </th>
                  <th scope="col" className="w-[15%] text-center  font-medium">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {getReceptionData &&
              getReceptionData.length > 0 &&
              getReceptionData?.map((pst, ind) => {
                return (
                  // <tr>
                  //   <th scope="row">{ind + 1}</th>
                  //   <td>
                  //     {pst.fName} {pst.lName}
                  //   </td>
                  //   <td>{pst.age}</td>
                  //   <td>{pst.gender}</td>
                  //   <td>{pst.contact}</td>
                  //   <td>
                  //     {pst.address}, {pst.city}
                  //   </td>
                  //   <td>
                  //     {pst.payId === 1 ? (
                  //       <>
                  //         <div className="text-green-500">Cash</div>
                  //       </>
                  //     ) : pst.payId === 2 ? (
                  //       <>
                  //         <div className="text-orange-500">UPI</div>
                  //       </>
                  //     ) : pst.payId === 3 ? (
                  //       <>
                  //         <div className="text-yellow-500">Follow up</div>
                  //       </>
                  //     ) : pst.payId === 4 ? (
                  //       <>
                  //         <div className="text-slate-500">Complementry</div>
                  //       </>
                  //     ) : null}
                  //   </td>

                  //   <td>
                  //     <td>
                  //       <button
                  //         type="button"
                  //         class="btn hover:bg-[#007bff] hover:text-white "
                  //         onClick={() => setEditRegFormOpen(true)}
                  //       >
                  //         Edit
                  //       </button>
                  //     </td>
                  //     <td>
                  //       <button
                  //         type="button"
                  //         class="btn hover:bg-[#dc3545] hover:text-white  "
                  //         onClick={() => setDeleteRegForm(true)}
                  //       >
                  //         Delete
                  //       </button>
                  //     </td>
                  //     <td>
                  //       <button
                  //         type="button"
                  //         className="btn hover:bg-[#28a745] hover:text-white "
                  //         onClick={() => setForwardFormOpen(true)}
                  //       >
                  //         Forward
                  //       </button>
                  //     </td>
                  //   </td>
                  // </tr>
                  <tr>
                  <td scope="row" className="text-center border-[1px]">{ind + 1}</td>
                  <td>
                  {pst.fName} {pst.lName}
                  </td>
                  <td className="text-center border-[1px]">{pst.age}</td>
                  <td className="text-center border-[1px]" >{pst.gender}</td>
                  <td className="border-[1px]">{pst.contact}</td>
                  <td className="border-[1px]">
                  {pst.address}</td>
                  <td className="border-[1px] text-center">
                  {pst.city}
                  </td>
                  <td className="text-center border-[1px]">
                  {pst.payId === 1 ? (
                        <>
                           <div className="text-green-500">Cash</div>
                         </>
                       ) : pst.payId === 2 ? (
                         <>
                           <div className="text-orange-500">UPI</div>
                         </>
                       ) : pst.payId === 3 ? (
                         <>
                           <div className="text-yellow-500">Follow up</div>
                         </>
                       ) : pst.payId === 4 ? (
                         <>
                           <div className="text-pink-800">Complementry</div>
                         </>
                       ) : null}
                  </td>


                  <td className="text-center border-[1px]">
                    <div className="flex justify-center items-center space-x-2">
                      <PencilSquareIcon
                        className="w-4 h-4 mx-2 text-blue-500 hover:text-blue-800 cursor-pointer"
                        onClick={() => { setEditRegFormOpen(true); setEditPatientDetail(pst)}}
                      />
                      <TrashIcon
                        className="w-4 h-4 mx-2 text-red-500 hover:text-red-800 cursor-pointer"
                        onClick={() => {setPatientId(pst._id); setDeleteRegForm(true)}}
                      />
                      <ArrowTopRightOnSquareIcon
                        className="w-4 h-4 ml-2 text-green-500 hover:text-green-800 cursor-pointer"
                        onClick={() => {setPatientId(pst._id); setForwardFormOpen(true)}}
                      />
                    </div>
                  </td>

                </tr> 
                );
              })}
                {/* <tr>
                  <td scope="row" className="text-center border-[1px]">1</td>
                  <td>
                    name
                  </td>
                  <td className="text-center border-[1px]">age</td>
                  <td className="text-center border-[1px]" >gender</td>
                  <td className="border-[1px]">contact</td>
                  <td className="border-[1px]">
                    address                </td>
                  <td className="border-[1px] text-center">
                    city
                  </td>
                  <td className="text-center border-[1px]">
                    cash
                  </td>


                  <td className="text-center border-[1px]">
                    <div className="flex justify-center items-center space-x-2">
                      <PencilSquareIcon
                        className="w-4 h-4 mx-2 text-blue-500 hover:text-blue-800 cursor-pointer"
                        onClick={() => setEditRegFormOpen(true)}
                      />
                      <TrashIcon
                        className="w-4 h-4 mx-2 text-red-500 hover:text-red-800 cursor-pointer"
                        onClick={() => setDeleteRegForm(true)}
                      />
                      <ArrowTopRightOnSquareIcon
                        className="w-4 h-4 ml-2 text-green-500 hover:text-green-800 cursor-pointer"
                        onClick={() => setForwardFormOpen(true)}
                      />
                    </div>
                  </td>

                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <EditRegistrationForm
        editRegFormOpen={editRegFormOpen}
        setEditRegFormOpen={setEditRegFormOpen}
        editPtDetail={editPatientDetail}
        getReceptionDetails={getReceptionDetails}
      />
      <DeleteModal
        openDelete={DeleteRegForm}
        setOpenDelete={setDeleteRegForm}
        id={patientId}
        url={"/reception/delete-patient/"}
        getData={getReceptionDetails}
      />
      <ForwardForm
        forwardFormOpen={forwardFormOpen}
        setForwardFormOpen={setForwardFormOpen}
        getData={getReceptionDetails}
        id={patientId}
        url={"/reception/forward-patient/"}
      />
    </>
  );
}

export default ViewAll;
