import { createSlice } from "@reduxjs/toolkit";

const bookingPolicySlice = createSlice({
  name: "policy",
  initialState: {
    bookingPolicy: {},
  },
  reducers: {
    setBookingPolicy(state, action) {
      state.bookingPolicy = action.payload;
    },
  },
});

export const { setBookingPolicy } = bookingPolicySlice.actions;
export default bookingPolicySlice.reducer;
