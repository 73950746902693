/** @format */

import "./App.css";
import React, { lazy, Suspense, useEffect, useState } from "react";
import Navbar from "./admin/layout/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { PropagateLoader } from "react-spinners";
import { Toaster } from "react-hot-toast";

import { Outlet } from "react-router-dom";
import { clearUser, setUser } from "./redux/features/userSlice";
import {
  fetch,
  resetBookingInLocalStorage,
  scrollToTop,
} from "./service/utils";
import { setBookingPolicy } from "./redux/features/bookingPolicy";

function App() {
  const loading = (
    <div className="flex justify-center h-screen items-center">
      <PropagateLoader color="#36d7b7" loading={true} size={15} />
    </div>
  );

  const userData = useSelector((state) => state.user.userData);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const verifyToken = async (token) => {
    try {
      setIsLoading(true);
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch("/user/token-info", "post", null, headers);
      setIsLoading(false);
      if (!response.data.ok) {
        setIsLoading(false);
        dispatch(clearUser());
        localStorage.removeItem("beenai_token");
        return;
      } else {
        setIsLoading(false);
        const { tokenData } = response.data;
        const userInfo = tokenData;
        dispatch(setUser(userInfo));
      }
    } catch (err) {
      setIsLoading(false);
      localStorage.removeItem("beenai_token");
      dispatch(clearUser());
    }
  };
  const token = localStorage.getItem("beenai_token");

  useEffect(() => {
    const token = localStorage.getItem("beenai_token");
    if (!token) {
      setIsLoading(false);
      localStorage.removeItem("beenai_token");
      dispatch(clearUser());
    } else {
      verifyToken(token);
    }
  }, [token]);

  return (
    <>
      {/* {userData.role_id == 1 ? */}
      {/* <Navbar /> */}
      {/* : null} */}

      <Suspense fallback={loading}>
        <div>
          <Toaster position="top-center" />
        </div>
        <Outlet />
      </Suspense>
    </>
  );
}

export default App;
