import React, { useEffect, useState } from "react";
import OptometristPatientAssessment from "../../components/modals/OptometristPatientAssessment";
import OptometristForward from "../../components/modals/OptometristForward";
import {
  ArrowTopRightOnSquareIcon,
  EyeDropperIcon,
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { fetch } from "../../../service/utils";
function Optometrist(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState("");
  const [addVisionDetail, setAddVisionDetail] = useState(false);
  const [addPetientDetails, setAddPetientDetails] = useState("");

  const [optoData, setOptoData] = useState([]);
  // console.log("helloogul", getOptoData)

  const [forwardFormOpen, setForwardFormOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPatient, setFilteredPatient] = useState([]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase() || "";
    setSearchQuery(query);

    const filtered =
      [] &&
      [].length > 0 &&
      []?.filter((pt) =>
        pt?.fname?.toLowerCase().includes(query)
      );
    setFilteredPatient(filtered);
  };

  const getOptoDetails = async (e) => {
    setIsLoading(true);
    setNetworkError("");

    try {
      const response = await fetch("/reception/get-forward-patient", "get", null);
      console.log("hello Ambreen",response)
      setIsLoading(false);
      if (response.data.success === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      } else {
        setOptoData(response.data.data);
      }
    } catch (err) {
      setIsLoading(false);
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Something went wrong. Please try again later.";
      setNetworkError(errorMessage);
      toast.error(errorMessage);
      console.log(err);
    }
  };

  useEffect(() => {
    getOptoDetails();
  }, []);

  return (
    <>
      <section className="px-20 pt-8 pb-20">
        <div className="inline-block w-full  border-[1px] border-gray-300 overflow-auto">
          <div className="overflow-auto">
            <div className="flex min-w-full  items-center justify-between py-2 mb-3 bg-[#0d5781]">
              <div className="font-semibold text-lg px-3 text-white ">
                Optometrist List
              </div>

              <Link to="/optometrist_dashboard" className="mr-10">
                <button
                  className="px-3 py-[6px]  text-base font-medium text-white bg-[#10a659] rounded-md shadow-md focus:outline-none focus:ring-2"

                >
                  Optometrist Dashboard
                </button>
              </Link>
            </div>
            <div className="flex min-w-full items-center justify-end  mb-2">

              <div className="border-[1px] border-[#0d5781] w-[20%] rounded-[4px] mr-10">
                <input
                  type="text"
                  placeholder="Search Name, Contact, City..."
                  className="px-2 py-[5px] rounded-[4px] w-full "
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>


            </div>
            <table class="table table-hover">
              <thead className="bg-[#0d5781] text-white">
                <tr>
                  <th scope="col" className="w-[3%] font-medium">
                    #
                  </th>
                  <th scope="col" className="w-[17%] font-medium">
                    Full Name
                  </th>
                  <th scope="col" className="w-[5%] text-center font-medium">
                    Age
                  </th>
                  <th scope="col" className="w-[7%] text-center font-medium">
                    Gender
                  </th>
                  <th scope="col" className="w-[13%]  font-medium">
                    Contact
                  </th>
                  <th scope="col" className="w-[20%]  font-medium">
                    Address
                  </th>
                  <th scope="col" className="w-[10%] text-center  font-medium">
                    City
                  </th>
                  <th scope="col" className="w-[10%]  text-center font-medium">
                    Payment
                  </th>
                  <th scope="col" className="w-[15%] text-center  font-medium">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
              {optoData &&
              optoData.length > 0 &&
              optoData?.map((pt, ind) => {
                return (
                <tr>
                  <td scope="row" className="text-center border-[1px]">{ind+1}</td>
                  <td>
                  {pt.fName}{pt.lName}
                  </td>
                  <td className="text-center border-[1px]">{pt.age}</td>
                  <td className="text-center border-[1px]" >{pt.gender}</td>
                  <td className="border-[1px]">{pt.contact}</td>
                  <td className="border-[1px]">
                    {pt.address}</td>
                  <td className="border-[1px] text-center">
                    {pt.city}
                  </td>
                  <td className="text-center border-[1px]">
                  {pt.payId === 1 ? (
                        <>
                           <div className="text-green-500">Cash</div>
                         </>
                       ) : pt.payId === 2 ? (
                         <>
                           <div className="text-orange-500">UPI</div>
                         </>
                       ) : pt.payId === 3 ? (
                         <>
                           <div className="text-yellow-500">Follow up</div>
                         </>
                       ) : pt.payId === 4 ? (
                         <>
                           <div className="text-slate-500">Complementry</div>
                         </>
                       ) : null}
                  </td>
                  <td className="text-center border-[1px]">
                    <div className="flex justify-center items-center space-x-2">

                      <EyeIcon
                        className="w-6 h-6 mx-2 text-blue-500 hover:text-blue-800 cursor-pointer"
                        onClick={() =>{ setAddVisionDetail(true);
                          setAddPetientDetails(pt)
                        }}
                      />

                      {/* <ArrowTopRightOnSquareIcon
                        className="w-5 h-5 ml-2 text-green-500 hover:text-green-800 cursor-pointer"
                        onClick={() => setForwardFormOpen(true)}
                      /> */}
                    </div>
                  </td>

                </tr>
);
})}
              </tbody>
            </table>
          </div>
        </div>
      </section >
      <OptometristPatientAssessment
        addVisionDetail={addVisionDetail}
        setAddVisionDetail={setAddVisionDetail}
        editProperDetail={addPetientDetails}
      />
      {/* <OptometristForward
        forwardFormOpen={forwardFormOpen}
        setForwardFormOpen={setForwardFormOpen}
      /> */}
    </>
  );
}

export default Optometrist;
