/** @format */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = () => {

  return (

    <footer className="bg-[#0d5781] text-white ">
      <div className="container mx-auto px-[30px] ">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1 */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Beenayi Clinic</h3>
            <ul>
              <li>
                <Link href="#" className="hover:underline">
                  About Us
                </Link>
              </li>
              <li>
                <Link href="#" className="hover:underline">
                  Careers
                </Link>
              </li>
              <li>
                <Link href="#" className="hover:underline">
                  Blog
                </Link>
              </li>
            </ul>
          </div>

          {/* Column 2 */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Support</h3>
            <ul>
              <li>
                <a href="#" className="hover:underline">
                  Help Center
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>

          {/* Column 3 */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <ul>
              <li>
                <a href="#" className="hover:underline">
                  Email: drsyedumair@gmail.com.com
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Phone: 0755-3137240
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Mob: 6267551823
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Address: C-191, BDA Colony Koh-e-Fiza, Bhopal
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 text-center border-t border-gray-700 pt-4">
          <p className="text-sm">
            &copy; 2024 Beenayi Clinic. All rights reserved.
          </p>
        </div>
      </div>
    </footer>

  );
};

export default Footer;