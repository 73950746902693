/** @format */

import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import TokenMiddleware from "../middleware/middleware";
import LoginMiddleware from "../middleware/login-middleware";
import LoginAdmin from "../pages/LoginAdmin";
import ErrorPage from "../pages/ErrorPage";
import Navbar from "../layout/Navbar";
const AdminDashboard = lazy(() => import("../pages/AdminDashboard"));
const AdminChangePwd = lazy(() => import("../pages/AdminChangePwd"));

const Admin = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            // <LoginMiddleware>
            <LoginAdmin />
            // </LoginMiddleware>
          }
        />
        <Route
          path="login"
          element={
            // <LoginMiddleware>
            <LoginAdmin />
            // </LoginMiddleware>
          }
        />
        {/* <Route
          path="changepwd"
          element={
            <TokenMiddleware>
              <Navbar />
              <AdminChangePwd />
            </TokenMiddleware>
          }
        /> */}
        <Route
          path="dashboard/*"
          element={
            // <TokenMiddleware>
            <>
              {" "}
              <Navbar />
              <AdminDashboard />
            </>
            // </TokenMiddleware>
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default Admin;
